<template>
	<div class="filter-sidebar">
		<div class="divider mx-20 vam"></div>
		<b-button v-b-toggle.sidebarFilters class="text-gray-900" id="side-btn">
			<span v-if="this.noText === false">
				Filters
			</span>
			<i
			v-if="this.noText === false"
			class="icon-filter text-gray-600 pl-10"
			></i>
			<i v-if="this.noText != false" class="icon-filter text-gray-600"></i>
		</b-button>
		<b-sidebar
		id="sidebarFilters"
		right
		backdrop
		backdrop-variant="dark"
		sidebar-class="filter-side"
		no-header
		shadow
		v-model="sidebarVisible"
		>
		<div class="d-flex flex-column h100vh px-36 pb-32 pt-36">
			<div class="header d-flex align-items-center pb-40">
				<i class="icon-filter text-gray-600 fs24 pr-16"></i>
				<h2 class="text-gray-900">Filters</h2>
				<b-button variant="rounded ml-auto" v-b-toggle.sidebarFilters>
					<i class="icon-close-variant"></i>
				</b-button>
			</div>

			<b-form-group>
				<multiselect
				id="channel"
				title="Carrier"
				v-model="form.carrier"
				:options="$store.getters.carriers"
				label="name"
				:searchable="true"
				:show-labels="false"
				placeholder="Select Carriers"
				class="mb-17"
				@input="addFilter({carrier:form.carrier})"
				></multiselect>
				<multiselect
				id="warehouse"
				v-model="form.address"
				:options="warehouses"
				title="Warehouse"
				label="name"
				:searchable="true"
				:show-labels="false"
				placeholder="Warehouse"
				class="mb-17"
				@input="addFilter({address:form.address})"
				></multiselect>
				<multiselect
				id="modeType"
				v-model="form.modeType"
				:options="$store.getters.mode.domestic"
				label="name"
				title="Mode"
				:searchable="true"
				:show-labels="false"
				placeholder="Mode type"
				class="mb-17"
				@input="addFilter({modeType:form.modeType})"
				></multiselect>
				<b-input
				class="mb-17"
				title="Search by AWB"
				id="awbNo"
				v-model="form.awbNo"
				@input="addFilter({awbNo:form.awbNo})"
				placeholder="AWB"
				></b-input>
				<b-form-textarea
				class="mb-17"
				rows="1"
				max-rows="3"
				title="Search by ManifestIDs"
				placeholder="Manifest ID's separated by comma"
				v-model="form.manifestIDs"
				@input="addFilter({manifestIDs:form.manifestIDs})"
				></b-form-textarea>
				<date-picker
				prefix-class="xmx"
				v-model="form.date"
				id="dp"
				range
				title="Date"
				value-type="format"
				format="YYYY-MM-DD"
				placeholder="Start Date - End Date"
				:disabled-date="(date) => date > new Date()"
				:editable="false"
				@input="addFilter({date:form.date})"
				></date-picker>
			</b-form-group>

			<div class="footer mt-auto text-right pb-32 pt-8">
				<b-link
				v-if="this.hasFilters"
				class="secondary fw500 mr-20"
				@click="clearFilters()"
				>
				Clear filters
			</b-link>
			<b-button
			:disabled="!this.hasFilters"
			variant="primary"
			@click="applyFilter"
			>
			Apply filter
		</b-button>
	</div>
</div>
</b-sidebar>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
	name: "FilterSidebar",
	props: [
	'warehouses',
	'noText'
	],
	data() {
		return {
			sidebarVisible: false,
			selectedFilter: 1,
			form: {},
			statuses: [{name:'Open', id:1}, {name:'Close', id:0}],
			selectedFilters: this.$store.getters.filters.manifest || {},
		};
	},
	components: {
		DatePicker,
		Multiselect,
	},
	created() {
		let form = Object.assign({}, this.$store.getters.filters.manifest)
		form.date = [this.selectedFilters.dateStart, this.selectedFilters.dateEnd]
		delete form.dateStart
		delete form.dateEnd
		this.form = form
	},
	methods: {
		async applyFilter(event) {
			if(this.selectedFilters.date) {
				this.selectedFilters.dateStart = this.selectedFilters.date[0]
				this.selectedFilters.dateEnd = this.selectedFilters.date[1]
				delete this.selectedFilters.date
			}
			this.sidebarVisible = false;
			this.$store.commit('setFilter', {'manifest': this.selectedFilters})
			this.$parent.getFilters();
		},
		addFilter(filter) {
			if(Object.values(filter)[0]) {
				this.selectedFilters = {...this.selectedFilters, ...filter};
			}else {
				let temp = this.selectedFilters
				delete temp[Object.keys(filter)[0]]
				this.selectedFilters = Object.assign({}, temp)
			}
		},
		clearFilters() {
			let filter = this.form
			this.form = {}
			this.selectedFilters = [];
			this.$store.commit('setFilter', {})
			if(Object.keys(filter).length>0) {
				this.$parent.clearFilters();
			}
		},
	},
	computed: {
		hasFilters() {
			if (Object.keys(this.selectedFilters).length>0) {
				return true;
			} else {
				return false;
			}
		},
	},
};
</script>

<style lang="scss">
.filter-sidebar {
	display: flex;
	align-items: center;
	white-space: nowrap;
	@include media-breakpoint-down(md) {
		.divider {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		button {
			i {
				margin: 0 !important;
				padding: 0 !important;
			}
			span {
				display: none;
			}
		}
		.b-sidebar-body {
			height: calc(100% - 86px);
		}
	}

	&.billing-sidebar {
		position: relative !important;
		top: 0;
		right: 0;
		z-index: 8;
	}
}
input.multiselect__input{
	height:auto;
	padding:0;
}
.tag.close{
	float: left;
}
</style>
